<template>
  <button type="button" class="btn">
    {{ value }}
  </button>
</template>

<script>
export default {
  props: ['value'],
}
</script>
