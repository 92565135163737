import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      disabled: false,
      title: '...',
      layout: 'default',
    },
    component: Home,
  },
  {
    path: '/students',
    name: 'students',
    meta: {
      title: 'My Students',
      bottomMenu: 'students',
    },
    redirect: '/students/manage', //ВРЕМЕННО!!
    component: () => import('@/pages/educator/my-students/MyStudents'),
    children: [
      {
        path: 'reports',
        name: 'student-reports',
        meta: {
          title: 'Student reports',
          bottomMenu: 'students',
        },
        redirect: '/students/manage', //ВРЕМЕННО!!
        component: () => import('@/pages/educator/my-students/Reports'),
      },
      {
        path: 'manage',
        name: 'manage-students',
        meta: {
          title: 'Manage students',
          bottomMenu: 'students',
        },
        component: () => import('@/pages/educator/my-students/Manage'),
      },
    ],
  },
  {
    path: '/classes',
    meta: {
      disabled: false,
      title: 'Classes',
      bottomMenu: 'classes',
    },
    redirect: '/classes/',
    component: () => import('@/pages/educator/classes/Classes'),
    children: [
      {
        path: '',
        name: 'classes',
        meta: {
          title: 'My classes',
          bottomMenu: 'classes',
        },
        exact: true,
        component: () => import('@/pages/educator/classes/ClassInfo'),
      },
      {
        path: 'create',
        name: 'create-class',
        meta: {
          title: 'Create new class',
          bottomMenu: 'classes',
        },
        component: () => import('@/pages/educator/classes/Create'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
