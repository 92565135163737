<template>
  <div class="text-center">
    <h4 class="mb-5">This Feature will be available soon</h4>
    <p class="fz-normal gray-color mb-8">
      The feature you tried to use is currently under development. We will let
      you know when you can use it. If you think this feature is important,
      press the vote button.
    </p>
    <h5 class="font-normal mb-4">Vote for this Feature</h5>
    <div class="flex justify-center gap-2 mb-2">
      <btn
        value="I’m not sure"
        class="fz-normal color-primary-lighter-bg text-white hover:text-white py-3 w-36"
      />
      <btn
        value="I realy want it"
        class="fz-normal secondary_dark_bg text-white hover:text-white py-3 w-36"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'vote-feature',
}
</script>

<style lang="scss" scoped></style>
