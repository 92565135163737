<template>
  <div
    class="cp-header-bottom bg-white px-6 flex justify-between items-center fz-normal py-1"
  >
    <div class="header-top-left">
      <div class="header-top-menu flex items-center">
        <div v-for="item in menu" :key="item.path">
          <router-link
            :to="item.path"
            class="gray-color fz-normal py-2 mr-4 default-link block"
            activeClass="active"
            exact
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-bottom',
  props: ['menu'],
}
</script>
