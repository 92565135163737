export default {
	'cart' : '<path d="M0.800781 2.21139C0.800781 2.54519 1.07341 2.82764 1.38323 2.82764H3.32264L4.28305 9.61922C4.41937 10.5885 4.91507 11.1791 5.86309 11.1791H13.0755C13.3667 11.1791 13.6331 10.9416 13.6331 10.6014C13.6331 10.2676 13.3667 10.03 13.0755 10.03H6.0056C5.70818 10.03 5.5223 9.81821 5.47892 9.49083L5.37978 8.82323H13.125C14.0731 8.82323 14.575 8.22624 14.7113 7.25051L15.176 4.03446C15.1884 3.95101 15.2008 3.84188 15.2008 3.77127C15.2008 3.39254 14.9467 3.13577 14.5192 3.13577H4.59286L4.49372 2.44249C4.41317 1.88401 4.19631 1.60156 3.50853 1.60156H1.38323C1.07341 1.60156 0.800781 1.88401 0.800781 2.21139ZM5.28684 13.2782C5.28684 13.9009 5.77014 14.4016 6.37118 14.4016C6.97221 14.4016 7.45551 13.9009 7.45551 13.2782C7.45551 12.6555 6.97221 12.1548 6.37118 12.1548C5.77014 12.1548 5.28684 12.6555 5.28684 13.2782ZM11.0059 13.2782C11.0059 13.9009 11.4954 14.4016 12.0965 14.4016C12.6975 14.4016 13.1746 13.9009 13.1746 13.2782C13.1746 12.6555 12.6975 12.1548 12.0965 12.1548C11.4954 12.1548 11.0059 12.6555 11.0059 13.2782Z" />',
	'graduationcap' : '<path d="M15.3756 3.37493L9.62003 0.446332C9.00147 0.131851 8.49485 -0.0122853 8 0.000818014C7.50515 -0.0122853 6.99853 0.131851 6.37997 0.446332L0.624448 3.37493C0.212077 3.57803 0 3.95803 0 4.34458C0 4.73768 0.212077 5.11112 0.624448 5.31422L2.90427 6.46077L6.72754 4.47561C6.70398 4.42975 6.69219 4.39044 6.69219 4.34458C6.69219 3.8663 7.3461 3.52562 8.01767 3.52562C8.69514 3.52562 9.34315 3.8663 9.34315 4.34458C9.34315 4.83595 8.69514 5.18319 8.01767 5.18319C7.81149 5.18319 7.59941 5.15043 7.4109 5.08492L3.82327 6.94559L6.37997 8.24937C6.99853 8.56385 7.50515 8.70144 8 8.69489C8.49485 8.70144 9.00147 8.56385 9.62003 8.24937L15.3756 5.31422C15.7879 5.10457 16 4.73768 16 4.34458C16 3.95803 15.7879 3.57803 15.3756 3.37493ZM3.79971 7.99386V11.8266C4.82474 12.508 6.27982 12.9207 8 12.9207C11.458 12.9207 13.8321 11.2631 13.8321 9.21902V7.15524L9.97938 9.12075C9.26657 9.48109 8.61856 9.66453 8 9.65798C7.38733 9.66453 6.73343 9.48109 6.02062 9.12075L3.79971 7.99386ZM2.16789 7.1618V9.21902C2.16789 9.78902 2.43888 10.4638 2.92784 11.0404V7.54834L2.16789 7.1618ZM2.40943 13.3793V15.0762C2.40943 15.6331 2.73932 16 3.24006 16H3.48748C3.98822 16 4.31222 15.6331 4.31222 15.0762V13.3793C4.31222 12.9469 4.11782 12.6324 3.79971 12.508V11.8266C3.45803 11.5973 3.16348 11.3352 2.92784 11.0404V12.508C2.60972 12.6259 2.40943 12.9469 2.40943 13.3793Z"/>',
	'feedback': '<path d="M13.1484 0.8125H2.85156C1.27937 0.8125 0 2.09188 0 3.66438V9.26063C0 10.8331 1.27937 12.1122 2.85156 12.1122H5.41469L7.62781 15.0013C7.81531 15.2463 8.18469 15.2459 8.37219 15.0013L10.5853 12.1122H13.1484C14.7206 12.1122 16 10.8331 16 9.26063V3.66438C16 2.09188 14.7206 0.8125 13.1484 0.8125ZM11.2834 7.15813L8.33156 10.11C8.14875 10.2931 7.85187 10.2931 7.66875 10.11L4.71656 7.15813C3.83375 6.27531 3.83375 4.84656 4.71656 3.96406C5.59719 3.08344 7.03 3.08344 7.91062 3.96406L8 4.05344L8.08937 3.96438C8.51594 3.5375 9.08313 3.3025 9.68625 3.3025C10.2897 3.3025 10.8569 3.5375 11.2834 3.96406C12.1641 4.84469 12.1641 6.2775 11.2834 7.15813Z"/><path d="M10.6205 6.49359L7.99983 9.1139L5.37921 6.49359C4.55515 5.66953 5.1314 4.23828 6.31327 4.23828C6.66639 4.23828 6.99796 4.37547 7.24764 4.62516L7.66858 5.04609C7.85139 5.22922 8.14858 5.22922 8.33139 5.04609L8.75202 4.62547C9.00171 4.37578 9.33327 4.23828 9.68639 4.23828C10.8545 4.23828 11.4533 5.66078 10.6205 6.49359Z"/>',
	'settings' : '<path d="M15.6438 7.14375L13.7719 6.68437C13.6688 6.35625 13.5375 6.0375 13.3781 5.72813L14.3344 4.13438C14.4469 3.94688 14.4187 3.7125 14.2688 3.5625L12.9375 2.23125C12.7875 2.08125 12.5531 2.05313 12.3656 2.16562L10.7719 3.12188C10.4625 2.9625 10.1438 2.83125 9.81563 2.72813L9.36563 0.85625C9.30938 0.65 9.12187 0.5 8.90625 0.5H7.03125C6.81563 0.5 6.62813 0.65 6.57188 0.85625L6.12187 2.72813C5.79375 2.83125 5.475 2.9625 5.16563 3.12188L3.57188 2.16562C3.38438 2.05313 3.15 2.08125 3 2.23125L1.66875 3.5625C1.51875 3.7125 1.49063 3.94688 1.60312 4.13438L2.55938 5.72813C2.4 6.0375 2.26875 6.35625 2.16563 6.68437L0.35625 7.14375C0.15 7.19063 0 7.37813 0 7.59375V9.46875C0 9.68437 0.15 9.87187 0.35625 9.91875L2.16563 10.3781C2.26875 10.7063 2.4 11.025 2.55938 11.3344L1.60312 12.9281C1.49063 13.1156 1.51875 13.35 1.66875 13.5L3 14.8313C3.15 14.9812 3.38438 15.0094 3.57188 14.8969L5.16563 13.9406C5.475 14.1 5.79375 14.2313 6.12187 14.3344L6.57188 16.1438C6.62813 16.35 6.81563 16.5 7.03125 16.5H8.90625C9.12187 16.5 9.30938 16.35 9.36563 16.1438L9.81563 14.3344C10.1438 14.2313 10.4625 14.1 10.7719 13.9406L12.3656 14.8969C12.5531 15.0094 12.7875 14.9812 12.9375 14.8313L14.2688 13.5C14.4187 13.35 14.4469 13.1156 14.3344 12.9281L13.3781 11.3344C13.5375 11.025 13.6688 10.7063 13.7719 10.3781L15.6438 9.91875C15.85 9.87187 16 9.68437 16 9.46875V7.59375C16 7.37813 15.85 7.19063 15.6438 7.14375ZM7.96875 10.875C6.675 10.875 5.625 9.825 5.625 8.53125C5.625 7.2375 6.675 6.1875 7.96875 6.1875C9.2625 6.1875 10.3125 7.2375 10.3125 8.53125C10.3125 9.825 9.2625 10.875 7.96875 10.875Z"></path>',
	'logout': '<g clip-path="url(#clip0_5805_76713)"><path d="M9.9999 8.66654C9.63125 8.66654 9.33328 8.96525 9.33328 9.33316V11.9999C9.33328 12.3672 9.03457 12.6665 8.66654 12.6665H6.66656V2.6666C6.66656 2.09727 6.30389 1.58861 5.75861 1.39928L5.56122 1.33324H8.66654C9.03457 1.33324 9.33328 1.63255 9.33328 1.99998V3.99996C9.33328 4.36788 9.63125 4.66658 9.9999 4.66658C10.3685 4.66658 10.6665 4.36788 10.6665 3.99996V1.99998C10.6665 0.89733 9.76919 0 8.66654 0H1.49998C1.47459 0 1.45335 0.0113524 1.4287 0.0146483C1.39659 0.0119628 1.36595 0 1.33336 0C0.598016 0 0 0.597895 0 1.33324V13.3331C0 13.9025 0.362667 14.4111 0.907949 14.6004L4.91999 15.9378C5.05598 15.9798 5.19123 15.9998 5.33332 15.9998C6.06866 15.9998 6.66656 15.4018 6.66656 14.6665V13.9999H8.66654C9.76919 13.9999 10.6665 13.1025 10.6665 11.9999V9.33316C10.6665 8.96525 10.3685 8.66654 9.9999 8.66654Z"/><path d="M15.806 6.19414L13.1392 3.52754C12.9487 3.33687 12.6619 3.2795 12.4127 3.38277C12.164 3.48616 12.0013 3.72945 12.0013 3.99885V5.99883H9.33471C8.96667 5.99883 8.66797 6.29741 8.66797 6.66545C8.66797 7.03349 8.96667 7.33207 9.33471 7.33207H12.0013V9.33205C12.0013 9.60145 12.164 9.84474 12.4127 9.94813C12.6619 10.0514 12.9487 9.99403 13.1392 9.80348L15.806 7.13676C16.0666 6.87614 16.0666 6.45476 15.806 6.19414Z"/></g><defs><clipPath id="clip0_5805_76713"><rect width="16" height="16" fill="white"/></clipPath></defs>',
	'arrowDown': '<path d="M0.58782 0.747336C0.912214 0.422942 1.43807 0.422655 1.76282 0.746695L5 3.97682L8.23718 0.746696C8.56193 0.422656 9.08779 0.422942 9.41218 0.747336C9.73682 1.07198 9.73682 1.59833 9.41218 1.92298L5.70711 5.62805C5.31658 6.01857 4.68342 6.01857 4.29289 5.62805L0.58782 1.92298C0.263176 1.59833 0.263176 1.07198 0.58782 0.747336Z" />',
	'circle': '<circle cx="6" cy="6" r="6" />',
	'users' : '<path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" />',
	


}