<template>
	<svg 
		:class="$attrs.class"
		:viewBox="$attrs.viewBox || '0 0 16 16'" 
		fill="currentColor" 
		xmlns="http://www.w3.org/2000/svg"
		v-html="path"
	></svg>
</template>

<script>
  import icons from '../utils/icons'
  export default {
    props: ['name'],
    data: () => ({
    	icons: icons,
    }),
    computed: {
    	path() {
    		return this.icons[this.name]
    	}
    }
  }
</script>