<template>
<modal 
    :name="name" 
    :scrollable="true"
    :draggable="draggable" 
    :height="height" 
    :width="width" 
    :shiftX="x" 
    :shiftY="y"
>
    <div class="modal-content relative pl-8 pr-10 py-4">
        <button type="button" class="btn-close" @click="close()" title="Close">
            <img src="/img/icons/close.svg" alt="Close" class="w-3">
        </button>
        <div class="modal-body">
            <vote-feature v-if="template == 'vote-feature'" />
            <add-students v-else-if="template == 'add-students'" />
            <class-schedule v-else-if="template == 'class-schedule'" />
            <p v-else-if="!template" class="text-center text-lg py-4">You forgot to specify a parameter
                <pre class="inline font-mono font-bold">template</pre>, which is responsible for the output of the template</p>
        </div>
    </div>
</modal>
</template>

<script>
import AddStudents from '@/components/modals/AddStudents'
import ClassSchedule from '@/components/modals/ClassSchedule'
import VoteFeature from '@/components/modals/VoteFeature'
export default {
    name: 'v-modal',
    props: {
        name: {
            type: String,
            required: true
        },
        template: {
            type: String
        },
        /* OPTIONS
         *  see all settings here - https://euvl.github.io/vue-js-modal/Properties.html#properties-2
         */
        draggable: {
            type: Boolean,
            default: false
        },
        width: {
            default: 600 //Width in pixels or percents (50, "50px", "50%")
        },
        height: {
            default: 'auto' //Height in pixels or percents (50, "50px", "50%") or "auto"
        },
        x: {
            default: 0.5
        },
        y: {
            default: 0.5
        }
    },
    components: {
        AddStudents,
        ClassSchedule,
        VoteFeature
    },
    methods: {
        close() {
            this.$modal.hide(this.name);
        }
    },
}
</script>

<style>
.btn-close {
    width: 30px;
    height: 30px;
    line-height: 1;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
    position: absolute;
    top: 19px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vm--modal{
    border-radius: 1rem !important;
    box-shadow: none !important;
}
</style>
