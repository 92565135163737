<template>
  <div @keydown.enter="done()">
    <h4 class="mb-2 text-center">Add students</h4>
    <h5 class="mb-1">
      Invite students by sharing your unique Educator Code or Link
    </h5>
    <p class="fz-normal gray-color mb-3">
      Give students access to their real-time statistics of your subject
    </p>
    <copy-link
      title="Class code:"
      value="TC-4582732US"
      :class="'flex-grow mb-2'"
    />
    <copy-link
      title="Class link:"
      value="https://newtonteach.me/"
      :class="'flex-grow'"
    />
    <hr class="my-4" />
    <form class="fz-normal mb-4" @submit.prevent="">
      <div class="search-input relative">
        <img
          src="/img/icons/search.svg"
          alt="Search"
          class="absolute w-4 ml-3"
        />
        <input
          type="text"
          class="rounded-3xl pl-8 pr-2 py-5 border-0 color-primary-lighter-bg gray-color w-full"
          placeholder="Search"
          v-model="search"
        />
      </div>
    </form>
    <div class="custom-scroll mb-4" v-if="allStudents.length">
      <user-checkbox
        v-for="s in filteredList"
        :key="s.id"
        :id="s.id"
        type="checkbox"
        :userName="s.name"
        :image="`https://randomfox.ca/images/${s.id}.jpg`"
        :checked="classStudentsId.includes(s.id) ? true : false"
        v-model="classStudentsId"
      />
    </div>
    <p class="fz-normal gray-color text-center mb-5" v-else>
      Your students list is empty. Share your class code or link to invite
      students to your classroom.
    </p>
    <p
      class="fz-normal gray-color text-center mb-5"
      v-if="!filteredList.length"
    >
      No results found for
      <b class="font-bold">{{ search }}</b>
    </p>
    <div class="flex justify-center">
      <btn
        value="Done"
        class="fz-normal secondary_dark_bg text-white hover:text-white py-3 px-16 mb-2"
        @click.native="done()"
      />
    </div>
  </div>
</template>

<script>
import UserCheckbox from '@/components/user/UserCheckbox'
export default {
  name: 'add-student',
  data: () => ({
    classStudentsId: [],
    allStudents: [],
    search: '',
  }),
  methods: {
    done() {
      this.$modal.hide('add-students')
      this.$store.commit('SET_TMP_STUDENTS_ID', this.classStudentsId)
    },
  },
  mounted: function () {
    this.allStudents = this.$store.getters.GET_STUDENTS
    this.classStudentsId = this.$store.getters.GET_TMP_STUDENTS_ID
  },
  computed: {
    filteredList() {
      return this.allStudents.filter((s) => {
        return s.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  components: {
    UserCheckbox,
  },
}
</script>

<style scope lang="sass">
.custom-scroll
    max-height: 280px
    margin-right: -22px
    padding-right: 8px
</style>
