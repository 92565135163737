import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueJsModal from 'vue-js-modal'
import VueConfirmDialog from 'vue-confirm-dialog'
import axios from 'axios'

// COMPONENTS
import Card from './components/elements/Card'
import CopyLink from './components/elements/CopyLink'
import FormGroup from './components/form/FormGroup'
import Button from './components/elements/Button'
import Modal from './components/modals/Modal'
// END COMPONENTS

// STYLES
import './assets/tailwind.css'
import '@/sass/cp/main.sass'
// END STYLES

Vue.config.productionTip = false

Vue.use(VueJsModal)
Vue.use(VueConfirmDialog)

// USE COMPONENTS
Vue.component('card', Card)
Vue.component('copy-link', CopyLink)
Vue.component('form-group', FormGroup)
Vue.component('btn', Button)
Vue.component('v-modal', Modal)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
// END USE COMPONENTS

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
