<template>
  <div>
    <h1 class="text-center mb-12 pt-6">Application Map</h1>
    <div class="flex">
      <ul class="font-normal">
        <h4
          class="leading-none pb-1 mb-4"
          style="border-bottom: 2px solid #e8d12c;"
        >
          Educator
        </h4>
        <li v-for="route in $router.options.routes" :key="route.path">
          <div v-if="!route.meta.disabled">
            <router-link :to="route.path">{{ route.meta.title }}</router-link>
          </div>
          <div v-else>
            <span>{{ route.meta.title }}</span>
          </div>
          <ul v-if="route.children" class="pl-5">
            <li v-for="child in route.children" :key="child.path">
              <router-link
                :to="route.path + '/' + child.path"
                class="primary_lighter"
              >
                {{ child.meta.title }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <a>Curriculun</a>
          <ul class="pl-5">
            <li><a>...</a></li>
          </ul>
        </li>
        <li>
          <a>Library</a>
          <ul class="pl-5">
            <li><a>...</a></li>
          </ul>
        </li>
      </ul>
      <ul class="font-normal">
        <h4
          class="leading-none pb-1 mb-4 text-gray-300"
          style="border-bottom: 2px solid #e36565;"
        >
          Students
        </h4>
        <li class="opacity-40">
          <router-link to="/">...</router-link>
        </li>
      </ul>
      <ul class="font-normal">
        <h4
          class="leading-none pb-1 mb-4 text-gray-300"
          style="border-bottom: 2px solid #e36565;"
        >
          Parents
        </h4>
        <li class="opacity-40">
          <router-link to="/">...</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
}
</script>
<style lang="sass" scope>
#app.map
  position: relative
  z-index: 1
  overflow: hidden
  &::before
    content: ''
    position: absolute
    width: 100%
    height: 100%
    left: 0
    right: 0
    top: 0
    bottom: 0
    background-image: url(/img/logos/logo-bg.png)
    background-size: 1%
    z-index: -1
    transform: rotate(-45deg) scale(10)
    opacity: .1
  .sidebar
    display: none
  .cp-wrapper
    margin: 0
  .cp-content
    max-width: 80%
    background-color: rgba(#fff, .5)
    border-radius: 1rem
    height: auto
    margin: 30px auto
    padding: 0 20px
    >div>div
      >ul
        width: 33%
        display: inline-block
        padding: 0 1.5%
        margin-bottom: 30px
        min-height: calc(100vh - 260px)
        border-right: 1px solid #ccc
        &:last-child
          border-right: none
.router-link-active
  background-color: #fff
li
  margin-bottom: 5px
  a
    padding: 4px 15px
    display: block
    transition: all .25s
    &:hover
      background-color: #fff
</style>
