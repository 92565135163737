import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import menus from './modules/menus'
import students from './modules/students'
import classes from './modules/classes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    menus,
    students,
    classes,
  },
})
