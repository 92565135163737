<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout'
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    },
  },
  components: {
    DefaultLayout,
  },
  mounted() {
    this.$store.commit('SET_BOTTOM_MENU', this.$router.options.routes)
  },
  created() {
    document.title = 'Newton app map'
  },
  beforeUpdate() {
    document.title =
      this.$route.meta.title == '...'
        ? 'Newton application map'
        : this.$route.meta.title
  },
}
</script>

<style lang="scss">
.new-confirm {
  .vc-container {
    width: 600px;
    box-shadow: none;
  }
  .vc-title {
    color: #0a81a4;
    font-size: 1.1252rem;
    margin: 0.5rem 0 1rem;
  }
  .vc-btn-grid {
    display: flex;
    justify-content: center;
    border-radius: 0;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  .vc-btn {
    border: 0 !important;
    width: 200px;
    background-color: #ec63a2;
    border-radius: 100px !important;
    transition: all 0.25s ease;
    color: #fff;
    font-weight: normal;
    font-size: 0.7501rem;
    &:hover {
      background-color: #f084bb;
    }
    &.left {
      background-color: #f6f6f6;
      color: #8d8d8d;
      &:hover {
        background-color: #efefef;
      }
    }
  }
}
</style>
