<template>
  <div>
    <h4 class="mb-2">Class Schedule</h4>

    <div class="flex justify-center">
      <btn
        type="button"
        value="Set time for this class"
        class="fz-normal secondary_dark_bg text-white hover:text-white py-3 px-8 mb-2"
      />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
