<template>
  <div
    class="grid fz-normal gap-2"
    :class="right ? 'grid-cols-2' : 'grid-cols-1'"
  >
    <div class="color-primary-lighter-bg rounded-lg overflow-hidden">
      <div class="grid grid-cols-4">
        <div class="color-primary-light-bg p-1.5 rounded-lg col-span-1">
          {{ title }}
        </div>
        <div class="col-span-3 px-1.5 flex items-center justify-between">
          <input
            type="text"
            :id="'copyInput-' + uid"
            :value="value"
            class="border-0 bg-transparent gray-color flex-grow pr-2 truncate"
            readonly
          />
          <button
            class="border-0 p-0 d-inline-flex"
            @click.prevent="copy"
            :disabled="copied"
          >
            <img
              src="/img/icons/copy.svg"
              class="w-3 h-auto"
              alt="Copy"
              :id="uid"
              v-if="!copied"
            />
            <img
              src="/img/icons/success-check.svg"
              class="w-3 h-auto"
              alt="Success"
              v-if="copied"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="color-primary-darker py-1.5" v-if="right">
      {{ right }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'value', 'right'],
  data: () => ({
    copied: false,
    uid: '',
  }),
  mounted: function () {
    this.uid = Math.random().toString(36).substring(7)
  },
  methods: {
    copy() {
      let id = event.target.getAttribute('id')
      let copyText = document.getElementById('copyInput-' + id)
      copyText.select()
      document.execCommand('copy')
      if (copyText.value != '') {
        // console.log(copyText.value);
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 1500)
      }
    },
  },
}
</script>

<style scoped lang="sass">
button[disabled]
	cursor: default
input::selection
	background-color: transparent
	color: rgba(115, 115, 115, 0.64)
</style>
