<template>
  <label
    class="new-label flex items-center justify-between rounded-3xl p-2 cursor-pointer"
  >
    <div class="flex items-center">
      <img
        :src="image"
        :alt="userName"
        class="flex-shrink-0 mr-2 rounded-full w-8 h-8 object-cover object-center"
      />
      <h5 class="m-0">{{ userName }}</h5>
    </div>
    <input :type="type" v-model="model" :value="id" ref="checkbox" />
    <span class="new-checkbox-radio new-primary new-checkbox"></span>
  </label>
</template>

<script>
export default {
  props: ['id', 'image', 'userName', 'type', 'checked', 'value'], //value ВСЕГДА ОБЯЗАТЕЛЕН!!!!
  computed: {
    model: {
      get() {
        return this.value //БЕРЕТСЯ ИЗ PROPS
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  mounted: function () {
    if (this.checked) {
      this.$refs.checkbox.checked = true
    }
  },
}
</script>

<style lang="scss" scope>
label {
  &:hover {
    background-color: #f6f6f6;
  }
}
</style>
