export default {
  state: {
    bottomMenu: {},
  },
  mutations: {
    SET_BOTTOM_MENU(state, menuArr) {
      for (let item in menuArr) {
        item = menuArr[item]
        let arr = []
        if (item.meta.bottomMenu && item.children) {
          for (let child in item.children) {
            child = item.children[child]
            arr.push({
              path: item.path + '/' + child.path,
              name: child.meta.title,
            })
          }
          state.bottomMenu[item.meta.bottomMenu] = arr
        }
      }
    },
  },
  getters: {
    GET_BOTTOM_MENU: (state) => state.bottomMenu,
  },
}
