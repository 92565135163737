<template>
  <div
    class="cp-header-top color-primary-lighter-bg px-6 flex justify-between items-center fz-normal py-1"
  >
    <div class="header-top-left">
      <div class="header-top-menu flex align-items-center">
        <router-link
          :to="{ name: 'classes' }"
          activeClass="active"
          class="gray-color font-bold fz-normal py-2 mr-4 default-link"
        >
          Classes
        </router-link>
        <router-link
          :to="{ name: 'students' }"
          activeClass="active"
          class="gray-color font-bold fz-normal py-2 mr-4 default-link"
        >
          Students
        </router-link>
        <a
          href="#"
          class="gray-color font-bold fz-normal py-2 mr-4 default-link"
        >
          Curriculun
        </a>
        <a
          href="#"
          class="gray-color font-bold fz-normal py-2 mr-4 default-link"
        >
          Library
        </a>
      </div>
    </div>
    <router-link
      :to="{ name: 'home' }"
      class="font-bold fz-normal py-1 px-4 default-link uppercase"
      style="color: #e36565;"
    >
      &rarr; Application Map &larr;
    </router-link>
    <div class="header-top-right flex items-center">
      <form class="mr-4">
        <div class="search-input relative">
          <img
            src="/img/icons/search.svg"
            alt="Search"
            class="absolute w-3 ml-2"
          />
          <input
            type="text"
            class="rounded-3xl pl-7 pr-2 py-2 border-0 bg-white gray-color"
            placeholder="Search"
          />
        </div>
      </form>

      <a
        href="http://127.0.0.1:8421/dashboard"
        class="btn btn-small-xs secondary_dark_bg text-white py-2 mr-4 px-3 hover:text-white"
      >
        Upgrade
      </a>

      <div 
        class="relative" 
        v-click-outside="hideUserMenu"
      >
        <a 
          href="#"
          @click.prevent="isShowUserMenu = !isShowUserMenu"
        >
          <img
          src="/img/educator/user/user.png"
          alt="USER"
          class="rounded-fill user-avatar-sm object-cover object-top"
          />
        </a>
        <div 
          class="absolute p-2 mt-3 top-full right-0 bg-white shadow-2xl rounded-xl w-56" 
          v-show="isShowUserMenu"
        >
          <a
            v-for="link in userMenu" 
            :href="link.url" 
            :key="link.title"
            class="user-menu-link flex items-center bg-gray-100 text-blue-200 rounded-lg px-2 py-1.5 mb-1 transition-all duration-200 hover:bg-gray-200 hover:text-blue-300"
          >
            <BaseIcon :name="link.icon" class="w-3"/>            
            <span class="ml-1.5">{{ link.title }}</span> 
          </a>
          <a
            href="javascript:void(0)" 
            class="flex items-center bg-gray-100 text-danger-500 rounded-lg px-2 py-1.5 mt-3 mb-2 transition-all duration-200 hover:bg-gray-200 hover:text-danger-800"
          >
            <BaseIcon name="logout" class="w-3"/>            
            <span class="ml-1.5">Log Out</span> 
          </a>

          <div class="flex flex-wrap items-center text-gray-800">
            <template v-for="link in userBottomMenu">
            <a 
              :href="link.url" 
              :key="link.title"
              class="text-gray-800 small-text hover:text-gray-900 whitespace-nowrap leading-3"
            >{{ link.title }}</a>
            <span class="mx-1">·</span> 
            </template>
            <span class="text-gray-700 font-semibold opacity-70 small-text whitespace-nowrap leading-3">Newton Teach Me ©{{ getCurrentYear }}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from './BaseIcon.vue'
  import ClickOutside from '@/utils/click-outside'
  export default {
    data: () => ({
      userMenu: [
        {title: 'Cart', url: 'javascript:void(0)', icon: 'cart'},
        {title: 'Knowledge Base', url: 'javascript:void(0)', icon: 'graduationcap'},
        {title: 'Feedback', url: 'javascript:void(0)', icon: 'feedback'},
        {title: 'Settings & Payments', url: 'javascript:void(0)', icon: 'settings'},
      ],
      userBottomMenu: [
        {title: 'Contact us', url: 'javascript:void(0)'},
        {title: 'Privacy Policy', url: 'javascript:void(0)'},
        {title: 'Terms of servise', url: 'javascript:void(0)'},
        {title: 'Cookie', url: 'javascript:void(0)'},
      ],
      isShowUserMenu: false,
    }),
    components: {
      BaseIcon,
    },
    computed: {
      getCurrentYear() {
        return new Date().getFullYear();
      },
    },
    methods: {
      hideUserMenu() {
        this.isShowUserMenu = false
      },
    },
    directives: {
      ClickOutside,
    },
  }
</script>

<style lang="sass">
.user-menu-link
  &:hover
    svg
      fill: #0a81a4
  svg
    transition: all .25s ease
</style>