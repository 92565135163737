export default {
  state: {
    classes: JSON.parse(localStorage.getItem('classes') || '[]'),
    currentClass: '',
  },
  mutations: {
    SET_NEW_CLASS(state, newClass) {
      state.classes.push(newClass)
      localStorage.setItem('classes', JSON.stringify(state.classes))
    },
    REBUILD_CLASSES(state, classes) {
      state.classes = classes
      localStorage.setItem('classes', JSON.stringify(state.classes))
    },
    SET_CURRENT_CLASS(state, classId) {
      state.currentClass = state.classes.filter((item) => classId == item.id)
    },
  },
  actions: {},
  getters: {
    GET_CLASSES: (state) => state.classes,
    GET_CURRENT_CLASS: (state) => state.currentClass,
  },
}
