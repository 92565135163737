<template>
  <div id="app" :class="{ map: this.$route.name == 'home' }">
    <TheSidebar />

    <div class="cp-wrapper min-h-screen">
      <div class="sticky top-0 z-40">
        <TheHeaderTop v-if="this.$route.path != '/'" />
        <TheHeaderBottom
          v-if="this.$route.path != '/' && this.$route.meta.bottomMenu"
          :menu="GET_BOTTOM_MENU[this.$route.meta.bottomMenu]"
        />
      </div>
      <div class="cp-content">
        <router-view />
      </div>
      <vue-confirm-dialog class="new-confirm"></vue-confirm-dialog>
    </div>
  </div>
</template>

<script>
import TheSidebar from '../components/TheSidebar'
import TheHeaderTop from '../components/TheHeaderTop'
import TheHeaderBottom from '../components/TheHeaderBottom'
import { mapGetters } from 'vuex'
export default {
  components: {
    TheSidebar,
    TheHeaderTop,
    TheHeaderBottom,
  },
  computed: {
    ...mapGetters(['GET_BOTTOM_MENU']),
  },
}
</script>
