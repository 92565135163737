<template>
	<div>
		<p class="gray-color fz-normal mb-2" v-if="title">{{title}}</p>
		<div 
			class="newton-form-group flex flex-col-reverse color-primary-lighter-bg rounded-2xl color-primary-darkest fz-normal leading-none relative mb-2"
			:class="{'required': required}"
		>
			<input 
				v-model="inputVal"
				:type="type || 'text'"
				:id="id"
				:required="required"
				class="bg-transparent rounded-2xl h-full w-full px-4 pt-5 pb-1 border-0" 
				placeholder=" " 
			>
			<label :for="id"
				class="absolute pl-0.5" 
				v-if="label"
			>{{label}}<span class="error-message ml-2">{{error}}</span>
			</label>
		</div>
	</div>
	
</template>

<script>
	export default {
		props: ['type', 'id', 'label', 'error', 'title', 'required'],
		// emits: ['update:modelValue'],
		computed: {
			inputVal: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				}
			}
		}
	}
</script>