<template>
	<div class="card">
		<div class="card-body" :class="bodyClass">
			<div class="flex justify-between items-center" :class="titleClass" v-if="title">
				<slot name="title"></slot>
			</div>

			<slot></slot>

		</div>
	</div>
</template>

<script>
	export default {
		props: [
		'bodyClass', 
		'title', 
		'titleClass'
		]
	}
</script>