import axios from 'axios'
export default {
  state: {
    students: [],
    tmpStudentsId: [],
  },
  mutations: {
    SET_STUDENTS(state, students) {
      state.students = students
    },
    SET_TMP_STUDENTS_ID(state, tmpStudentsId) {
      state.tmpStudentsId = tmpStudentsId
    },
  },
  actions: {
    students_request({ commit }) {
      axios
        .get('../api/students.json')
        .then((res) => {
          commit('SET_STUDENTS', res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
  getters: {
    GET_STUDENTS: (state) => state.students,
    GET_TMP_STUDENTS_ID: (state) => state.tmpStudentsId,
  },
}
